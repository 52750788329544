import { RiTelegramFill, RiTwitterXFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Connect from '../Buttons/Connect';
import { useWallet } from '@solana/wallet-adapter-react';
import Disconnect from '../Buttons/Disconnect';
import { getSetting } from '../../services/Supabase';

const Header = () => {
  const { connected } = useWallet();
  const [hideConnect, setHideConnect] = useState(false);

  useEffect(() => {
    getSetting('hide_connect_button')
      .then((response) => {
        setHideConnect(response.value === 'true');
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <header>
      <div className="container">
        <nav>
          <div className="nav-logo">
            <Link to={'/'}>
              <img src={'/images/memeo-abstract.png'} alt="Memeo abstract" />
            </Link>
          </div>

          <ul className="nav-links">
            <li className="link">
              <a
                href="https://x.com/memeodotfun"
                target="_blank"
                rel="noreferrer"
              >
                <RiTwitterXFill size={20} color="white" className="icon" />
              </a>
            </li>
            <li className="link">
              <a
                href="https://t.me/memeodotfun"
                target="_blank"
                rel="noreferrer"
              >
                <RiTelegramFill size={20} color="white" className="icon" />
              </a>
            </li>
            {hideConnect === false && (
              <li className="link">
                {connected ? <Disconnect /> : <Connect />}
              </li>
            )}
          </ul>
        </nav>

        <div className="nav-mid-logo">
          <Link to={'/'}>
            <img
              src={'/images/memeo-logo.png'}
              width="275px"
              alt="Memeo logo"
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
