import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { List, NotFound, Settings } from './components/pages';
import Home from './components/pages/home/Home';
import WalletContext from './contexts/WalletContext';

const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/list',
    element: <List />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WalletContext>
      <RouterProvider router={router} />
    </WalletContext>
  </React.StrictMode>
);
