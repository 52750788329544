import './Card.css';
import { RiTelegramFill } from 'react-icons/ri';
import React, { FC } from 'react';
import Meme from '../../interfaces';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';

const Card: FC<Meme> = (meme) => {

  const isMobile = useCheckMobileScreen();

  const handleOnClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <div className="card" onClick={() => handleOnClick(meme.link)}>
      <div className="new">
        <RiTelegramFill color="#24A1DE" size={30} onClick={() => handleOnClick(meme.telegram)}/>
      </div>
      <div className="avatar">
        <div className="inner">
          <img src={meme.icon} alt="140x130"/>
        </div>
      </div>
      <div className="description">
        <p className="title">{meme.name}</p>
        <div className="tags">
          {isMobile ? (
            <>
              <div className="tag">Ticker: {meme.ticker ?? '-'} </div>
            </>
          ) : (
            <>
              <div className="tag">Ticker: <br/> {meme.ticker ?? '-'} </div>
            </>
          )}
        </div>
        <p className="info">{meme.description}</p>
      </div>
    </div>
  );
};

export default Card;
