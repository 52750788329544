import React, { useState } from 'react';
import Switch from 'react-switch';
import './Setting.css';
import { setSetting } from '../../services/Supabase';

type Type = 'boolean' | 'string';

type Setting = {
  id: number;
  setting: string;
  name: string;
  type: Type;
  value: string;
};

type SettingProps = {
  setting: Setting;
};

const Setting: React.FC<SettingProps> = ({ setting }) => {
  const [checked, setChecked] = useState(setting.value === 'true');

  const handleChange = (e: any) => {
    let value = !checked;
    setChecked(value);
    setSetting(setting.setting, value);
  };

  switch (setting.type) {
    case 'boolean':
      return (
        <div className="setting">
          <div key={setting.setting}>{setting.name}</div>
          <div>
            <Switch onChange={handleChange} checked={checked} />
          </div>
        </div>
      );

    default:
      return <>Geen setting</>;
  }
};

export default Setting;
