import React from 'react';

interface ErrorProps {
  message?: string;
}

const CustomError: React.FC<ErrorProps> = ({ message }) => {
  return (
    <p style={{ color: 'red', marginBottom: 10 }}>
      {message}
    </p>
  );
};

export default CustomError;