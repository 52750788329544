import { useForm } from 'react-hook-form';
import './Form.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  WalletNotConnectedError,
  WalletSendTransactionError,
} from '@solana/wallet-adapter-base';
import { PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { PHANTOM_PUBLIC_KEY, SOL_TRANSFER_AMOUNT } from '../../constants';
import CustomError from '../Errors/CustomError';
import { getSetting, insertMeme } from '../../services/Supabase';
import { sendToTelegram } from '../../services/Telegram';

interface IFormInput {
  id: bigint;
  name: string;
  ticker: string;
  description: string;
  referral: string;
  icon: string;
  link: string;
  telegram: string;
  position: bigint;
  createdAt: Date;
}

const url = `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/sendPhoto`;

const Form: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const [setting, setSetting] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const hideSubmitButton = () => {
    getSetting('hide_submit_button')
      .then((response) => {
        setSetting(response.value !== 'true');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const payment = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(PHANTOM_PUBLIC_KEY),
        lamports: SOL_TRANSFER_AMOUNT,
      })
    );

    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext();

    const signature = await sendTransaction(transaction, connection, {
      minContextSlot,
    });

    await connection.confirmTransaction({
      blockhash,
      lastValidBlockHeight,
      signature,
    });
  }, [publicKey, sendTransaction, connection]);

  const onSubmit = async (formData: IFormInput) => {
    try {
      await payment();
      await insertMeme(formData);
      await sendToTelegram(url, formData);
      setSuccess(true);
    } catch (err) {
      if (err instanceof WalletSendTransactionError) {
        setError('Transaction rejected by the user.');
      } else {
        setError('An unexpected error occurred. Try again.');
      }
    }
  };

  const validateUrl = (value: string) => {
    let url;

    try {
      url = new URL(value);
    } catch (_) {
      return 'URL is not valid.';
    }

    const checkUrls =
      value.toLowerCase().includes('pump.fun') ||
      value.toLowerCase().includes('dexscreener.com');

    if (!checkUrls) {
      return 'pump.fun or dexscreener.com';
    }

    return true;
  };

  const isImage = async (url: string): Promise<boolean> => {
    try {
      const res = await fetch(url, { method: 'HEAD' });
      const contentType = res.headers.get('Content-Type');
      return contentType ? contentType.startsWith('image') : false;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    hideSubmitButton();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {success ? (
        <>
          <div className="success">
            Your meme has successfully been listed. It will appear shortly
            after.
          </div>
        </>
      ) : (
        <>
          {error && (
            <>
              <CustomError message={error.toString()} />
            </>
          )}
          <div className="form-group">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Enter meme name"
              {...register('name', {
                required: true,
              })}
            />
            {errors?.name?.type === 'required' && (
              <p className="error">Enter meme name</p>
            )}
            {errors?.name?.type === 'pattern' && (
              <p className="error">Illegal characters used</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="ticker" className="required">
              Ticker
            </label>
            <input
              id="ticker"
              type="text"
              placeholder="Enter meme ticker"
              {...register('ticker', {
                required: true,
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="description" className="required">
              Description
            </label>
            <textarea
              id="description"
              placeholder="Enter meme description"
              {...register('description', {
                required: true,
              })}
            ></textarea>
            {errors?.description?.type === 'required' && (
              <p className="error">Enter meme description</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="icon" className="required">
              Icon
            </label>

            <input
              id="icon"
              type="text"
              placeholder="Enter meme icon"
              {...register('icon', {
                required: true,
                validate: isImage,
              })}
            />
            {errors?.icon?.type === 'required' && (
              <p className="error">Enter an icon link</p>
            )}
            {errors.icon?.type === 'validate' && (
              <p className="error">Link is not a valid image</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="link" className="required">
              Link
            </label>
            <input
              id="link"
              type="text"
              placeholder="pump.fun or dexscreener.com"
              {...register('link', {
                required: true,
                validate: validateUrl,
              })}
            />
            {errors?.link?.type === 'required' && (
              <p className="error">Enter meme link</p>
            )}
            {errors?.link?.type === 'validate' && (
              <p className="error">{errors?.link?.message}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="referral">Referral</label>
            <input
              id="referral"
              type="text"
              placeholder="Referral"
              {...register('referral')}
            />
          </div>

          <div className="form-group">
            <label htmlFor="telegram">Telegram</label>
            <input
              id="telegram"
              type="text"
              placeholder="Telegram"
              {...register('telegram')}
            />
          </div>

          {setting === true && (
            <div className="form-group">
              <button type="submit">List Now {setting}</button>
            </div>
          )}

          <p className="listing">
            Listing a meme costs <span>0.5 SOL</span>
          </p>
        </>
      )}
    </form>
  );
};

export default Form;
