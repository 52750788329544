import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { RiLinkUnlinkM } from 'react-icons/ri';

const Disconnect: React.FC = () => {
  const { disconnect, wallet } = useWallet();

  // Function to format the wallet address
  const formatAddress = (address: string | undefined) => {
    return address
      ? `${address.substring(0, 3)}...${address.substring(address.length - 3)}`
      : '';
  };

  return (
    <div className="disconnect" onClick={disconnect}>
      <RiLinkUnlinkM size={20} className="icon" />
      {formatAddress(wallet?.adapter.publicKey?.toBase58())}
    </div>
  );
};

export default Disconnect;
