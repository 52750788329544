import React, { useEffect, useState } from 'react';
import Header from '../../Header/Header';
import Copyright from '../../Copyright/Copyright';
import { getSettings } from '../../../services/Supabase';
import Setting from '../../Setting/Setting';

const Settings: React.FC = () => {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const checkPassword = (e: any) => {
    e.preventDefault();

    if (password === 'eQb1HF6Q38') {
      setVerified(true);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const data = await getSettings();
        if (Array.isArray(data)) {
          setSettings(data);
        } else {
          setError('Failed to fetch settings.');
        }
      } catch (err) {
        setError('An error occurred while fetching settings.');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  return (
    <>
      <div>
        <Header />

        <section className="container">
          <div className="settings">
            {!verified ? (
              <form onSubmit={checkPassword}>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </form>
            ) : (
              <>
                {loading ? (
                  <>
                    <p>Loading...</p>
                  </>
                ) : (
                  <>
                    {settings.length > 0 ? (
                      <>
                        {settings.map((setting) => (
                          <Setting key={setting.setting} setting={setting} />
                        ))}
                      </>
                    ) : (
                      <p>No settings found</p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </section>

        <Copyright />
      </div>
    </>
  );
};

export default Settings;
