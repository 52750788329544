import React, { useEffect, useState } from 'react';
import { supabase } from '../../../supabaseClient';
import { Copyright, Header, HighlightedMemes } from '../../index';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';
import Pagination from '../../Pagination/Pagination';
import ColoredLine from '../../ColoredLine/ColoredLine';
import { ITEMS_PER_PAGE } from '../../../constants';

const Home: React.FC = () => {
  const [memes, setMemes] = useState<any | null>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  async function getMemes() {
    setLoading(true);

    const { data } = await supabase
      .from('meme')
      .select()
      .order('position', { ascending: true });

    setMemes(data);
    setLoading(false);
  }

  useEffect(() => {
    getMemes();
  }, []);

  const handleSearchInput = async (event: any) => {
    if (event.target.value !== '') {
      setSearch(event.target.value);
      setLoading(true);

      const { data } = await supabase
        .from('meme')
        .select()
        .ilike('name', `%${search}%`);

      setMemes(data);
      setLoading(false);
    } else {
      await getMemes();
    }
  };

  return (
    <div className="App">
      <Header />

      <section id="header">
        <div className="container">
          <div className="content">
            <Link to={'/list'}>
              <button className="list">List Meme</button>
            </Link>

            <div className="search-container">
              <input
                type="text"
                className="search"
                placeholder="Search name"
                onChange={handleSearchInput}
              />
              <RiSearchLine size={20} className="search-icon" />
            </div>
          </div>
        </div>
      </section>

      <section id="content">
        <div className="container">
          {loading ? (
            <p style={{ color: '#fff' }}>Searching..</p>
          ) : (
            <>
              {memes.length > 0 ? (
                <>
                  <HighlightedMemes memes={memes.slice(0, 3)} />

                  {memes.length > 3 && (
                    <Pagination
                      items={memes.slice(3, memes.length.size)}
                      itemsPerPage={ITEMS_PER_PAGE}
                    />
                  )}
                </>
              ) : (
                <p className="empty">Be the first one to showcase your Meme!</p>
              )}
            </>
          )}
        </div>
      </section>

      <ColoredLine />

      <Copyright />
    </div>
  );
};

export default Home;
