import Copyright from '../../Copyright/Copyright';
import Header from '../../Header/Header';
import '../../../App.css';
import Form from '../../Form/Form';
import ColoredLine from '../../ColoredLine/ColoredLine';

const List = () => {
  return (
    <div>
      <Header/>

      <section className="form">
        <Form/>
      </section>

      <ColoredLine/>

      <Copyright/>
    </div>
  );
};

export default List;
