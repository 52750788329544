import React from 'react';
import './HighlightedMemes.css';
import { RiTelegramFill } from 'react-icons/ri';
import Meme from '../../interfaces';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';

interface HighlightedMemesProps {
  memes: any[] | null;
}

const sort = (memes: any, customOrder: [number, number, number]) => {
  const customSort = (a: any, b: any) => {
    return customOrder.indexOf(a.position) - customOrder.indexOf(b.position);
  };

  return memes.sort(customSort);
};

const HighlightedMemes: React.FC<HighlightedMemesProps> = ({ memes }) => {

  const mobile = useCheckMobileScreen();
  const sortedMemes = mobile ? sort(memes, [1, 2, 3]) : sort(memes, [2, 1, 3]);

  const handleOnClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <div className="highlighted-cards">
      {sortedMemes.length === 1 && (
        <>
          <div className="card visibility-none">
            <div className="new">
              <RiTelegramFill
                color="#24A1DE"
                size={30}
                onClick={() => handleOnClick(sortedMemes[0].telegram)}
              />
            </div>
            <div className="avatar">
              <div className="inner">
                <img src={sortedMemes[0].icon} alt="140x130"/>
              </div>
            </div>
            <div className="description">
              <p className="title">{sortedMemes[0].name}</p>
              <div className="tags">
                <div className="tag">
                  Ticker: {sortedMemes[0].ticker ?? '-'}{' '}
                </div>
              </div>
              <p className="info">{sortedMemes[0].description}</p>
              <RiTelegramFill color="blue"/>
            </div>
          </div>
        </>
      )}
      {sortedMemes &&
        sortedMemes.map((meme: Meme, index: number) => (
          <div
            className="card"
            onClick={() => handleOnClick(meme.link)}
            key={meme.id}
          >
            <div className="new">
              <RiTelegramFill
                color="#24A1DE"
                size={30}
                onClick={() => handleOnClick(meme.telegram)}
              />
            </div>
            <div className="avatar">
              <div className="inner">
                <img src={meme.icon} alt="140x130"/>
              </div>
            </div>
            <div className="description">
              <p className="title">{meme.name}</p>
              <div className="tags">
                <div className="tag">Ticker: {meme.ticker ?? '-'} </div>
              </div>
              <p className="info">{meme.description}</p>
            </div>
          </div>
        ))}
      {sortedMemes.length === 1 && (
        <>
          <div className="card visibility-none">
            <div className="new">
              <RiTelegramFill
                color="#24A1DE"
                size={30}
                onClick={() => handleOnClick(sortedMemes[0].telegram)}
              />
            </div>
            <div className="avatar">
              <div className="inner">
                <img src={sortedMemes[0].icon} alt="140x130"/>
              </div>
            </div>
            <div className="description">
              <p className="title">{sortedMemes[0].name}</p>
              <div className="tags">
                <div className="tag">
                  Ticker: {sortedMemes[0].ticker ?? '-'}{' '}
                </div>
              </div>
              <p className="info">{sortedMemes[0].description}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HighlightedMemes;
