import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { RiLinkM } from 'react-icons/ri';
import { PhantomWalletName } from '@solana/wallet-adapter-phantom';

const Connect: React.FC = () => {
  const { connected, select } = useWallet();

  const handleConnect = () => {
    if (connected) {
      return;
    }

    select(PhantomWalletName);
  };

  return (
    <div onClick={handleConnect}>
      <RiLinkM size={20} className="icon" />
    </div>
  );
};

export default Connect;
