import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import Meme from '../../interfaces';
import Card from '../Card/Card';

interface PaginationProps {
  items: Meme[];
  itemsPerPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ items, itemsPerPage }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="cards">
        {currentItems &&
          currentItems.map((meme: Meme) => <Card {...meme} key={meme.id} />)}
      </div>
      <div className="paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel={<RiArrowRightSLine size={20} />}
          previousLabel={<RiArrowLeftSLine size={20} />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
        />
      </div>
    </>
  );
};

export default Pagination;
