import React from 'react';
import { Copyright, Header } from '../../index';

const NotFound: React.FC = () => {
  return (
    <div>

      <Header/>

      <section>
        <div className="container">
          404 Page Not Found
        </div>
      </section>

      <Copyright/>

    </div>
  );
};

export default NotFound;