import { supabase } from '../supabaseClient';
import Meme from '../interfaces';

export const insertMeme = async (memeData: Meme) => {
  try {
    const { error, data } = await supabase.from('meme').insert([
      {
        name: memeData.name,
        ticker: memeData.ticker,
        description: memeData.description,
        referral: memeData.referral,
        icon: memeData.icon,
        link: memeData.link,
        position: 0,
        telegram: memeData.telegram,
        created_at: new Date(),
      },
    ]);

    if (error) {
      return error;
    }

    return data;
  } catch (error) {
    return error;
  }
};

export const getSettings = async () => {
  const { data, error } = await supabase
    .from('settings')
    .select('*')
    .order('id');

  if (error) {
    console.error('Error fetching setting:', error);
    return null;
  }

  return data;
};

export const getSetting = async (setting: string) => {
  const { data, error } = await supabase
    .from('settings')
    .select('*')
    .eq('setting', setting)
    .single();

  if (error) {
    console.error('Error fetching setting:', error);
    return null;
  }

  return data;
};

export const setSetting = async (setting: string, value: string | boolean) => {
  const { data, error } = await supabase
    .from('settings')
    .update({
      value: value,
    })
    .eq('setting', setting)
    .single();

  if (error) {
    console.error('Error fetching setting:', error);
    return null;
  }
};
