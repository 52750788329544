import axios from 'axios';
import Meme from '../interfaces';

export const sendToTelegram = async (url: string, formData: Meme) => {
  return axios
  .post(url, {
    chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
    parse_mode: 'html',
    photo: formData.icon,
    caption: `Name: ${formData.name}\nTicker: ${formData.ticker}\nDescription: ${formData.description}\nLink: ${formData.link}\nTelegram: <a href="${formData.telegram}">${formData.telegram}</a>\n\nNOW LIVE ON MEMEO.FUN!
      `,
  })
  .then((response) => {
    console.log(response.data);
    return response.data;
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};
